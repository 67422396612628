export const initialState = {
  contract: "",
  query: {},
  attributes: {},
  dateRange: {},
};

export const queryReducer = (state = initialState, action) => {
  switch (action.type) {
    case "query/contract": {
      return {
        ...state,
        contract: action.payload,
      };
    }
    case "query/query": {
      return {
        ...state,
        query: action.payload,
      };
    }
    case "query/date": {
      return {
        ...state,
        dateRange: action.payload,
      };
    }
    case "query/attributes": {
      return {
        ...state,
        attributes: action.payload,
      };
    }

    default:
      return state;
  }
};
