import {
  SummaryListRow,
  SummaryListKey,
  SummaryListValue,
  P,
} from "../components/govuk-react";

const AdviceOutcome = ({adviceOutcome}) => {

  const adviceMethods = adviceOutcome.advicemethod && !adviceOutcome.advicemethod.includes(null || undefined) ? 
  adviceOutcome.advicemethod.map((method, index) => {
    return <P style={{ marginTop: "0px", marginBottom: "0px"}} key={index}>{method[0]}</P> 
  }) : null;

  return (
    <>
      <SummaryListRow>
        <SummaryListKey>Advice type</SummaryListKey>
        <SummaryListValue>{adviceOutcome?.advice ? adviceOutcome.advice[0] : null}</SummaryListValue>
      </SummaryListRow>
      <SummaryListRow>
        <SummaryListKey>Advice methods</SummaryListKey>
        <SummaryListValue>
          {adviceMethods}
        </SummaryListValue>
      </SummaryListRow>
      <SummaryListRow>
        <SummaryListKey>Test conducted</SummaryListKey>
        <SummaryListValue>{adviceOutcome?.test ? adviceOutcome?.test[0] : null}</SummaryListValue>
      </SummaryListRow>
      <SummaryListRow>
        <SummaryListKey>Benefit</SummaryListKey>
        <SummaryListValue>{adviceOutcome?.benefit ? adviceOutcome?.benefit[0] : null}</SummaryListValue>
      </SummaryListRow>
    </>
  )
}

export default AdviceOutcome

