import styled from "styled-components";
import theme from "../theme";

export const InputHint = styled.span`
  font-size: 1.1875rem;
  line-height: 1.31579;
  font-weight: 400;
  display: block;
  margin-bottom: 15px;
  color: ${theme.colors.darkGrey};
`;
InputHint.displayName = "InputHint";
