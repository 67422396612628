import api from "../core";

export const addNewConsultation = async (contract, patientData) => {
  try {
    const { data } = await api.post(`/${contract}/consultation/`, patientData);
    return { response: data };
  } catch (error) {
    return error;
  }
};

export const updateConsultation = async (contract, patientData, consultationId) => {
  try {
    if(consultationId){
      const { data } = await api.put(`/${contract}/consultation/${consultationId}`, patientData);
      return { response: data };
    }
    else throw "Consultation ID was not passed in to API call"
  } catch (error) {
    console.log(error)
    return error;
  }
};

export const getConsultations = async (contract, patientId) => {
  try {
    const { data } = await api.get(`/${contract}/consultation/patient/${patientId}`);
    return data
  } catch (error) {
    return error;
  }
}
