export const convertTimestampToDate = (timestamp) => {
  const date = new Date(timestamp);
  const formattedDate = date.toLocaleDateString('en-GB'
  // , {
  //   day: '2-digit', month: 'long', year: 'numeric'
  // }
  )
  return formattedDate;
}

export const convertDOBToTimestamp = (day, month, year) => {
  if(day < 10 && day > 0 && day.length === 1) {
    day = `0${day}`;
  }

  if(month < 10 && month > 0 && month.length === 1) {
    month = `0${month}`;
  }
  return `${year}-${month}-${day} 00:00:00`;
  
}

export const deleteFields = (fields, object) => {
  fields.forEach((field) => {
    delete object[field]
  })
  return object
}