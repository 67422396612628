import styled from "styled-components";
import theme from "../theme";

export const AccordionHeadingButton= styled.button`
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding: 0;
  border-width: 0;
  color: inherit;
  background: none;
  text-align: left;
  cursor: pointer;
  -webkit-appearance: none;
  font-weight: 700;
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.11111;
  display: inline-block;
  margin-bottom: 0;
  background-size: auto;
  /* padding-top: 15px; */

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
  }

  &:hover:not(:focus) {
    text-decoration: underline;
  }

  &:focus {
    outline: 3px solid transparent;
    color: ${theme.colors.black};
    background-color: ${theme.colors.yellow};
    -webkit-box-shadow: 0 -2px ${theme.colors.yellow}, 0 4px ${theme.colors.black};
    box-shadow: 0 -2px ${theme.colors.yellow}, 0 4px ${theme.colors.black};
    text-decoration: none;
  }

  @media (min-width: 40.0625em) {
    /* font-size: 24px;
    font-size: 1.5rem; */
    font-size: 19px;
    line-height: 1.25;
  }
`;

AccordionHeadingButton.displayName = "AccordionHeadingButton";
