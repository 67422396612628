import styled, { css } from "styled-components";
import theme from "../theme";

const leftSelectedStyle = css`
  border-bottom: none;
  background-color: ${theme.colors.lightGrey};
  &:before {
    top: 0;
    bottom: 0;
    background-color: ${theme.colors.lightBlue};
  }
`;

const leftSelectedStyleError = css`
  border-bottom: none;
  background-color: ${theme.colors.lightGrey};
  &:before {
    top: 0;
    bottom: 0;
    background-color: ${theme.colors.red};
  }
`;

const leftStyle = css`
  cursor: pointer;
  line-height: 16px;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    top: 10px;
    bottom: 10px;
    left: 0px;
    width: 5px;
    background-color: ${theme.colors.midGrey};
  }
`;

const leftUnselectedStyleError = css`
  &:before {
    background-color: ${theme.colors.red};
  }
`;

const selectedStyle = css`
  border-bottom: 4px solid ${theme.colors.darkBlue};
  ${({ borderBottom }) => borderBottom && { borderBottom }};
  ${({ borderColor }) =>
    typeof borderColor === "string" && { borderColor: theme.colors[borderColor] }};
  text-decoration: none !important;
`;

const pointerStyle = css`
  cursor: pointer;
`;

export const NavigationItem = styled.li`
  box-sizing: border-box;
  height: ${({ height = "53px" }) => height};
  line-height: ${({ lineHeight = "53px" }) => lineHeight};
  padding: 0 15px;

  &[aria-selected="true"] {
    ${selectedStyle}
    ${({ enableLeftStyle }) => (enableLeftStyle === true ? leftSelectedStyle : "")};
    ${({ enableLeftStyleError }) =>
      enableLeftStyleError === true ? leftSelectedStyleError : ""};
  }

  ${({ selected }) => (selected === true ? selectedStyle : "")};
  ${({ enableLeftStyle }) => (enableLeftStyle === true ? leftStyle : "")};
  ${({ enableLeftUnselectedStyleError }) =>
    enableLeftUnselectedStyleError === true ? leftUnselectedStyleError : ""};
  ${({ enablePointerStyle }) => (enablePointerStyle === true ? pointerStyle : "")};
`;

NavigationItem.displayName = "NavigationItem";
