import styled, { css } from "styled-components";

const errorStyle = css`
  padding-left: 15px;
  border-left: 5px solid #d4351c;
`;

export const FormGroup = styled.form`
  ${({ marginBottom = "30px" }) => marginBottom && {marginBottom}}};
  ${({ isValid = true }) => (isValid ? null : errorStyle)};
`;

FormGroup.displayName = "FormGroup";
