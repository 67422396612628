import {getAllPatientsFromSearch} from './../../api/iapt/patient';

export const initialState = {
  search_results: ""
}

export const searchResultsReducer = (state = initialState, action) => {
  switch(action.type) {
    case("SR/getPatientData"): {
      return {
        search_results: action.payload
      }
    }
    default:
      return state
  }
} 

export const getPatientData = (data) => {
  return (dispatch) => {
    getAllPatientsFromSearch(data)
      .then(res => dispatch(
        {
          type: "SR/getPatientData",
          payload: res.name === "Error" ? [] : res
        }
      )
    )
  } 
}
