import styled from "styled-components";

export const RadioLabel = styled.label`
  padding: 11px 15px 10px 1px;
  margin-top: -2px;
  display: inline-block;
  margin-bottom: 0;
  cursor: pointer;
  touch-action: manipulation;
  font-size: 1rem;
  line-height: 1.31579;
  font-weight: 400;

  &::before {
    top: 8px;
    width: 24px;
    height: 24px;
    content: "";
    box-sizing: border-box;
    position: absolute;
    left: 0;
    border: 2px solid currentColor;
    border-radius: 50%;
    background: white;
  }

  &::after {
    top: 15px;
    left: 7px;
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border: 5px solid currentColor;
    border-radius: 50%;
    opacity: 0;
    background: currentColor;
  }
`;
