import React from "react";
import styled, { css } from "styled-components";
import theme from "../theme";
import { PaginationItem } from "./PaginationItem";
import { PaginationNext, PaginationPrev } from "./PaginationIterators";

const noop = () => "";

const link = css`
  color: ${theme.colors.blue};
  display: block;
  padding: 5px 10px;
  text-decoration: none;
  cursor: pointer;

  &:link {
    color: ${theme.colors.blue};
  }

  &:focus {
    outline: 0;
  }

  &:hover,
  &:focus {
    background: transparent;
    outline: 3px solid ${theme.colors.yellow};
  }
`;

const selected = css`
  color: ${theme.colors.black};
  display: block;
  padding: 5px 10px;
  text-decoration: none;
  cursor: default;
  font-weight: 700;
  border: none;
  pointer-events: none;

  &:focus {
    outline: 0;
  }

  &:hover,
  &:focus {
    color: ${theme.colors.black};
    background: none;
    outline: 3px solid ${theme.colors.yellow};
  }
`;

const disabled = css`
  color: ${theme.colors.midGrey};
  display: block;
  text-decoration: none;
  cursor: default;
  border: none;
  pointer-events: none;

  &:focus {
    outline: 0;
  }

  &:hover,
  &:focus {
    background: none;
    outline: 3px solid ${theme.colors.yellow};
  }
`;

const localTheme = {
  link,
  selected,
  disabled
};

const PaginationLink = styled.a`
  display: inline-block;
  padding: 10px 5px 10px 5px;
  margin-right: 15px;
  text-decoration: none;

  ${({ variant = "link" }) => localTheme[variant]}
`;

PaginationLink.displayName = "PaginationLink";

export const PaginationLinkPrevPage = ({ page, onPage, mute }) => {
  const onClick = (evt) => {
    evt.preventDefault();
    onPage(page - 1);
  };

  return (
    <PaginationItem>
      <PaginationLink
        variant={mute ? "disabled" : "link"}
        aria-label="Previous page"
        aria-disabled={mute}
        onClick={mute ? noop : onClick}
        href="!#"
      >
        <PaginationPrev />
      </PaginationLink>
    </PaginationItem>
  );
};

export const PaginationLinkNextPage = ({ page, onPage, mute }) => {
  const onClick = (evt) => {
    evt.preventDefault();
    onPage(page + 1);
  };

  return (
    <PaginationItem>
      <PaginationLink
        variant={mute ? "disabled" : "link"}
        aria-label="Next page"
        aria-disabled={mute}
        onClick={mute ? noop : onClick}
        href="!#"
      >
        <PaginationNext />
      </PaginationLink>
    </PaginationItem>
  );
};

export const PaginationLinkPage = ({ page, onPage, selected }) => {
  const onClick = (evt) => {
    evt.preventDefault();
    onPage(page);
  };

  return (
    <PaginationItem>
      <PaginationLink
        variant={selected ? "selected" : "link"}
        aria-label={`Page ${page}`}
        aria-current={selected}
        onClick={onClick}
        href="!#"
      >
        {page}
      </PaginationLink>
    </PaginationItem>
  );
};
