import styled from "styled-components";
import theme from "../theme";

export const SummaryListKey = styled.dt`
  margin: 0;
  margin-bottom: 5px;
  font-weight: 700;

  @media (min-width: 40.0625em) {
    width: 30%;
    margin-bottom: 5px;
    font-weight: 700;
    word-wrap: break-word;
    overflow-wrap: break-word;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid ${theme.colors.midGrey};
    display: table-cell;
    padding-right: 20px;
  }
`;
