import styled from "styled-components";
import theme from "../theme";

export const InputLabel = styled.label`
  font-size: 1.1875rem;
  line-height: 1.31579;
  font-weight: 700;
  color: ${theme.colors.black};
  display: block;
  margin-bottom: 5px;
`;
InputLabel.displayName = "InputLabel";
