import React from "react";
import { InputLabel, InputHint, Input } from ".";
import { FormGroup } from "../";

export const InputField = ({
  label,
  name,
  helpText,
  onChange,
  value,
  size,
  width,
  valid,
  disabled = false,
  marginBottom,
}) => {
  const handleOnChange = (e) => {
    onChange(e.target.value);
  };

  return (
    <FormGroup marginBottom={marginBottom}>
      {label ? <InputLabel htmlFor={name}>{label}</InputLabel> : null}
      {helpText ? <InputHint id={`${name}-hint`}>{helpText}</InputHint> : null}
      <Input
        id={name}
        name={name}
        onChange={handleOnChange}
        value={value}
        size={size}
        width={width}
        aria-describedby={`${name}-hint`}
        data-test={`input-${name}`}
        isValid={valid}
        disabled={disabled}
      />
    </FormGroup>
  );
};
