import { withRouter } from "react-router-dom";
import {
  SummaryList,
  SummaryListRow,
  SummaryListKey,
  SummaryListValue,
  ButtonLink,
  Accordion,
  P,
  H1,
  H2,
  H3,
  Link,
  FlexContainer,
} from "../components/govuk-react";
import {connect} from 'react-redux';
import AdviceSummary from "./AdviceSummary";
import { convertTimestampToDate } from "../utils/helpers";

const PatientSummary = ({patient_record, 
  patient_information, 
  employment_details, 
  employer_details, 
  patient_summary_notes,
  advice_sessions}) => {
  

  const exposures = employment_details.exposure && employment_details.exposure.map((exposure, index) => {
    return <P style={{ marginTop: "0px", marginBottom: "0px"}} key={index}>{exposure[0]}</P> 
  })

  const healthproblems = patient_information.healthproblem && patient_information.healthproblem.map((healthproblem, index) => {
    return <P  style={{ marginTop: "0px", marginBottom: "0px"}} key={index}>{healthproblem[0]}</P> 
  })

  const dob = patient_record.dob.join("/");

  const adviceSessions = advice_sessions && advice_sessions.map((session, index) => {
    return <AdviceSummary key={index} index={index} adviceSession={session} />
  })

  /*
  const patientSummaryNotes = patient_summary_notes && patient_summary_notes.map((note) => {
    return note.patientconsultationnotes !== null ? 
       (
        <>
          <SummaryListRow>
            <SummaryListKey>
              {convertTimestampToDate(note.patientconsultationdate)}
            </SummaryListKey>
            <SummaryListValue>
              {note.patientconsultationnotes}
            </SummaryListValue>
          </SummaryListRow>
        </>
        )
     : (<></>)
  })
  */
  
  return (
    <>
      <FlexContainer alignItems='center' justifyContent='space-between'>
        <H1>Patient record</H1>
        <P>Patient number: {patient_record.patientnumber}</P>
      </FlexContainer>

      <H2>Patient reference information</H2>
      <FlexContainer>
        <SummaryList>
          <SummaryListRow>
            <SummaryListKey>Date of birth</SummaryListKey>
            <SummaryListValue>{dob}</SummaryListValue>
          </SummaryListRow>
          <SummaryListRow>
            <SummaryListKey>First part of postcode</SummaryListKey>
            <SummaryListValue>{patient_record.post_code}</SummaryListValue>
          </SummaryListRow>
        </SummaryList>
      </FlexContainer>
      <Link to='/create_record?update=true'>Update</Link>

      <H2>Patient summary</H2>
      <Accordion title='Patient information'>
        <SummaryList>
          <SummaryListRow>
            <SummaryListKey>Sex</SummaryListKey>
            <SummaryListValue>{patient_information.sex && patient_information.sex[0]}</SummaryListValue>
          </SummaryListRow>
          <SummaryListRow>
            <SummaryListKey>Ethnic group</SummaryListKey>
            <SummaryListValue>{patient_information.ethnicity && patient_information.ethnicity[0]}</SummaryListValue>
          </SummaryListRow>
          <SummaryListRow>
            <SummaryListKey>Health problems</SummaryListKey>
           <SummaryListValue>        
            {healthproblems} 
          </SummaryListValue>
          </SummaryListRow>
          <SummaryListRow>
            <SummaryListKey>Referred from</SummaryListKey>
            <SummaryListValue>{patient_information.referredby && patient_information.referredby[0]}</SummaryListValue>
          </SummaryListRow>
          <SummaryListRow>
            <SummaryListKey>GP surgery</SummaryListKey>
            <SummaryListValue>{patient_information.practice && patient_information.practice[0]}</SummaryListValue>
          </SummaryListRow>
        </SummaryList>
        <Link to='/form/patient_info'>Update patient information</Link>
      </Accordion>

      <Accordion title='Employment details'>
        <SummaryList>
          <SummaryListRow>
            <SummaryListKey>Employment status</SummaryListKey>
            <SummaryListValue>{employment_details.currentstatus && employment_details.currentstatus[0]}</SummaryListValue>
          </SummaryListRow>
          <SummaryListRow>
            <SummaryListKey>Job title</SummaryListKey>
            <SummaryListValue>{employment_details.job && employment_details.job[0]}</SummaryListValue>
          </SummaryListRow>
          <SummaryListRow>
            <SummaryListKey>Shift pattern</SummaryListKey>
            <SummaryListValue>{employment_details.workPattern}</SummaryListValue>
          </SummaryListRow>
          <SummaryListRow>
            <SummaryListKey>Employment Sector</SummaryListKey>
            <SummaryListValue>{employment_details.generaljob && employment_details.generaljob[0]}</SummaryListValue>
          </SummaryListRow>
          <SummaryListRow>
            <SummaryListKey>Workplace exposures</SummaryListKey>      
            {exposures}
          </SummaryListRow>
        </SummaryList>
        <Link to='/form/employment_details'>Update employment details</Link>
      </Accordion>

      <Accordion title='Employer details'>
        <H3>Organisation details</H3>
        <SummaryList>
          <SummaryListRow>
            <SummaryListKey>Name</SummaryListKey>
            <SummaryListValue>{employer_details.employerName}</SummaryListValue>
          </SummaryListRow>
          <SummaryListRow>
            <SummaryListKey>Address</SummaryListKey>
            <SummaryListValue>
              {employer_details.employerAddressLine1}
              <br />
              {employer_details.employerAddressLine2}
              <br />
              {employer_details.employerAddressLine3}
              <br />
              {employer_details.employerPostCode}
            </SummaryListValue>
          </SummaryListRow>
        </SummaryList>
        <H3>Point of contact</H3>
        <SummaryList>
          <SummaryListRow>
            <SummaryListKey>Name of person or team</SummaryListKey>
            <SummaryListValue>{employer_details.nameOfContact}</SummaryListValue>
          </SummaryListRow>
          <SummaryListRow>
            <SummaryListKey>Phone number</SummaryListKey>
            <SummaryListValue>{employer_details.numberOfContact}</SummaryListValue>
          </SummaryListRow>
          <SummaryListRow>
            <SummaryListKey>Email address</SummaryListKey>
            <SummaryListValue>{employer_details.emailOfContact}</SummaryListValue>
          </SummaryListRow>
        </SummaryList>
        <Link to='/form/employer_details'>Update employer details</Link>
      </Accordion>

      <Accordion title='Patient summary notes'>
          <SummaryList>
          {//patientSummaryNotes
          }
        </SummaryList> 
        <Link to='/form/patient_summary_notes'>Update patient summary notes</Link>
      </Accordion>

      <H2>Advice sessions and outcomes</H2>
      <ButtonLink to='/form/session_outcomes'>
        Start a new advice session
      </ButtonLink>
      <br/>
      <br/>
      {adviceSessions}
    </>
  );
};

const mapStateToProps = (state) => {
  const { patient_profile } = state
  return { 
    patient_record: patient_profile.create_record,
    patient_information: patient_profile.patient_information,
    employment_details: patient_profile.employment_details,
    employer_details: patient_profile.employer_details,
    patient_summary_notes: patient_profile.patient_summary_notes,
    advice_sessions: patient_profile.advice_sessions
  }
}

export default withRouter(connect(mapStateToProps)(PatientSummary));
