import styled, { css } from "styled-components";
import theme from "../theme";

const validationErrorStyle = css`
  border: 4px solid ${theme.colors.red};
`;

export const Input = styled.input`
  font-size: 1.1875rem;
  line-height: 1.31579;
  font-weight: 400;
  box-sizing: border-box;
  width: ${({ width }) => width ? width : "100%"};
  height: 40px;
  margin-top: 0;
  padding: 5px;
  border: 2px solid ${theme.colors.black};
  border-radius: 0;
  -webkit-appearance: none;

  &:focus {
    outline: 3px solid ${theme.colors.yellow};
    outline-offset: 0;
  }

  ${({ isValid = true }) => (isValid ? null : validationErrorStyle)}
`;

Input.displayName = "Input";
