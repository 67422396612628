export const SOHASColors = {
  red: "#d4351c",
  lightRed: "#f3dede",
  yellow: "#ffdd00",
  green: "#00703c",
  blue: "#1d70b8",
  darkBlue: "#003078",
  lightBlue: "#5694ca",
  lighterBlue: "#dbeff9",
  darkerBlue: "#002664",
  midBlue: "#045EA4",
  purple: "#4c2c92",
  black: "#0b0c0c",
  darkerGrey: "#4E5455",
  darkGrey: "#6f777b",
  midGrey: "#b1b4b6",
  lightGrey: "#f3f2f1",
  lowGrey: "#f1f1f1",
  white: "#ffffff",
  lightPurple: "#6f72af",
  brightPurple: "#912b88",
  darkPurple: "#882345",
  pink: "#d53880",
  lightPink: "#f499be",
  orange: "#f47738",
  brown: "#b58840",
  lightGreen: "#85994b",
  turquoise: "#28a197",
  textPrimary: "#0b0c0c",
  textSecondary: "#6f777b",
  linkDefault: "#1d70b8",
  linkHover: "#003078",
  linkVisited: "#4c2c92",
  linkActive: "#0b0c0c",
  borderDefault: "#b1b4b6",
  borderInput: "#0b0c0c",
  focusDefault: "#ffdd00",
  focusText: "#0b0c0c",
  error: "#d4351c",
  brand: "#1d70b8",
  lightHeader: "#e0e1dd",
  backgroundGrey: "rgba(0, 0, 0, 0.3)",
};

const breakpoints = ["40em", "48em", "52em"];

const typography = {
  body: {
    fontSize: "18px",
    lineHeight: "24px",
  },
  h1: {
    fontSize: "40px",
  },
  h2: {
    fontSize: "32px",
  },
  h3: {
    fontSize: "24px",
  },
  h4: {
    fontSize: "18px",
  },
};

export const fontSizes = {};

const space = [
  "0",
  ".25rem",
  "1rem",
  "2rem",
  "4rem",
  "4.8rem",
  "8rem",
  "16rem",
  "32rem",
  "64rem",
];

const theme = {
  breakpoints,
  mediaQueries: {
    mobile: `@media screen and (min-width: ${breakpoints[0]})`,
    desktop: `@media screen and (max-width: ${breakpoints[1]})`,
  },
  typography,
  space,
  colors: SOHASColors,
};

export default theme;
