import React from "react";
import { RadioItem, RadioLabel, RadioInput } from ".";

export const Radio = ({ label, name, checked, onChange, isDisabled }) => (
  <RadioItem>
    <RadioInput
      type="radio"
      id={name}
      onChange={() => onChange(label)}
      checked={checked}
      disabled={isDisabled}
    />
    <RadioLabel htmlFor={name}>{label}</RadioLabel>
  </RadioItem>
);
