import styled from "styled-components";
import theme from "../theme";

export const SummaryListValue = styled.dt`
  display: table-cell;
  padding-right: 20px;

  @media (min-width: 40.0625em) {
    width: 50%;
    margin-bottom: 5px;
    word-wrap: break-word;
    overflow-wrap: break-word;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid ${theme.colors.midGrey};
    display: table-cell;
    padding-right: 20px;
  }
`;
