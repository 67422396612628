import styled, { css } from "styled-components";

const closedStyle = css`
  display: none;
`;

const openStyle = css`
  display: block;
`;

export const AccordionSectionContent = styled.div`
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 40px;

  ${({ open = false }) => (open ? openStyle : closedStyle)};

  & > :last-child {
    margin-bottom: 0;
  }

  @media (min-width: 40.0625em) {
    padding-bottom: 15px;
  }
`;

AccordionSectionContent.displayName = "AccordionSectionContent";
