import * as R from 'ramda';
import { convertDOBToTimestamp } from '../utils/helpers';

const dropdownMappings = JSON.parse(sessionStorage.getItem("dropdownMappings"))

const workPattern = { "Part-time" : 2, "Full-time" : 1 };

export const prepOptionsForMultiSelect = (option) => {return {label: option, value: option}}

export const prepInitialValsForMultiSelect = (option) => {return {label: option[0], value: option[0]}}

const mapMultiSelectToRedux = (key, value) => {
  return R.map(item => {
    return [item.value, dropdownMappings[key][item.value]]
  }, value)
}

const mapMultiSelectToDB = (multiSelectVals) => {
  const getIntegers = (item) => {
    return item[1]
  }
  return R.compose(R.flatten(), R.map(getIntegers))(multiSelectVals)
}

const mapIndexToDropdown = (property, index) => {
  for(const [key, val] of Object.entries(dropdownMappings[property])){
    if(val === index){
      return [key, val]
    }
  }
}

const mapAdviceMethods = (outcome) => {
  let adviceMethods = []
  outcome.patientconsultationadviceadvocacyyn === 1 && adviceMethods.push(["Advocacy", 1])
  outcome.patientconsultationadvicecounsellingyn === 1 && adviceMethods.push(["Counselling", 2])
  outcome.patientconsultationadviceinformationyn === 1 && adviceMethods.push(["Information", 3])
  outcome.patientconsultationadviceletteryn === 1 && adviceMethods.push(["Letter", 4])
  outcome.patientconsultationadvicephoneyn === 1 && adviceMethods.push(["Phone", 5])
  outcome.patientconsultationadviceverbalyn === 1 && adviceMethods.push(["Verbal", 6])
  outcome.patientconsultationadviceemployerconsultationyn === 1 && adviceMethods.push(["Workplace Visit", 7])
  return adviceMethods
}

export const mapDropdownToIndex = (values) => {

  const multiSelectList = ["healthproblem", "exposure", "referredto", "advicemethod"]
  let indexMap = Object.assign({}, values)

  for (const [key, value] of Object.entries(values)) {
    if(multiSelectList.includes(key) && value !== null){
      indexMap[key] = mapMultiSelectToRedux(key, value)
    }
    else{
      dropdownMappings[key] && (indexMap[key] = [value, dropdownMappings[key][value]])
    }
  }

  return indexMap
}

const mapOutcomes = outcome => {
  return {
    benefit: outcome.benefitid ? mapIndexToDropdown("benefit", outcome.benefitid) : [],
    advice: outcome.adviceid ? mapIndexToDropdown("advice", outcome.adviceid) : [],
    test: outcome.testid ? mapIndexToDropdown("test", outcome.testid) : [],
    advicemethod: mapAdviceMethods(outcome)
  }
}

export const mapConsultationsToFE = (consultation) => {
  return {
    patientconsultationdate: consultation.patientconsultationdate,
    adviser: consultation.adviserid ? mapIndexToDropdown("adviser", consultation.adviserid) : [],
    contacttype: consultation.contacttypeid ? mapIndexToDropdown("contacttype", consultation.contacttypeid) : [],
    outcomes: consultation.outcomes ? consultation.outcomes.map(mapOutcomes) : null,
    patientconsultationnotes: consultation.patientconsultationnotes ? consultation.patientconsultationnotes : null,
    referredto: consultation.referredTo ? consultation.referredTo.map(referral => (
      mapIndexToDropdown("referredto", referral.referredtoid)
    )) : [],
    consultationId: consultation.patientconsultationid
  }
}


export const mapFEtoDB = (data, type) => {

  const dob = data.dob ? convertDOBToTimestamp(data.dob[0], data.dob[1], data.dob[2]) : null;

  let mappedData
  switch(type){
    case("PP/createRecord"):
      mappedData = {
        patientdob: dob,
        patientpostcode: data.post_code
      }
      break
    case("PP/updateCreateRecord"):
      mappedData = {
        patientdob: dob,
        patientpostcode: data.post_code
      }
      break
    case("PP/updatePatientInfo"):
      mappedData = {
        patientjobid: data.patientjobid ? data.patientjobid : null,
        sexid: data.sex ? data.sex[1] : null, 
        healthproblemids: data.healthproblem && mapMultiSelectToDB(data.healthproblem),
        referredbyid: data.referredby ? data.referredby[1] : null,
        ethnicityid: data.ethnicity ? data.ethnicity[1] : null,
        practiceid: data.practice ? data.practice[1] : null
      }
      break
    case("PP/updateEmploymentDetails"):
      mappedData = {
        patientjobid: data.patientjobid ? data.patientjobid : null,
        jobid: data.job ? data.job[1] : null,
        currentstatusid: data.currentstatus ? data.currentstatus[1] : null,
        patient0neither1ft2pt: workPattern[data.workPattern],
        generaljobid: data.generaljob ? data.generaljob[1] : null,
        exposureids: data.exposure && mapMultiSelectToDB(data.exposure)
      }
      break
    case("PP/updateEmployerDetails"):
      mappedData = {
        patientjobid: data.patientjobid ? data.patientjobid : null,
        patientemployer: data.employerName,
        patientjobcontact1address: data.employerAddressLine1,
        patientjobcontact2address: data.employerAddressLine2,
        patientjobcontact3address: data.employerAddressLine3,
        patientjobcontactpostcode: data.employerPostCode,
        patientjobcontact: data.nameOfContact,
        patientjobcontactphone: data.numberOfContact,
        patientjobcontactemail: data.emailOfContact
      }
      break
    case("PP/updatePatientSummaryNotes"):
      mappedData = {

      }
      break
    case("AS/addAdviceOutcome"):
      mappedData={
        Consultation: {
          Consultation: {
            patientid: data.patientid,
            patientconsultationdate: data.patientconsultationdate,
            adviserid: data.adviser && data.adviser[1],
            contacttypeid: data.contacttype && data.contacttype[1],
            patientconsultationpracticesessionyn: 0,
            patientconsultationnotes: data.patientconsultationnotes && data.patientconsultationnotes
          },
          Advice: data.outcomes ? data.outcomes.map(outcome => {
            const adviceMethods = outcome.advicemethod.map(method => {
              return method[0]
            })
            return {
              adviceid: outcome.advice && outcome.advice[1],
              patientconsultationadviceadvocacyyn: adviceMethods.includes("Advocacy") ? 1 : 0,
              patientconsultationadvicecounsellingyn: adviceMethods.includes("Counselling") ? 1 : 0,
              patientconsultationadviceemployerconsultationyn: adviceMethods.includes("Workplace Visit") ? 1 : 0,
              patientconsultationadviceinformationyn: adviceMethods.includes("Information") ? 1 : 0,
              patientconsultationadviceletteryn: adviceMethods.includes("Letter") ? 1 : 0,
              patientconsultationadvicephoneyn: adviceMethods.includes("Phone") ? 1 : 0,
              patientconsultationadviceverbalyn: adviceMethods.includes("Verbal") ? 1 : 0,
              benefitid: outcome.benefit && outcome.benefit[1],
              testid: outcome.test && outcome.test[1]
            }
          }) : null, 
          ReferredTo: data.referredto && data.referredto[0] ? data.referredto.map(referral => (
            { referredtoid: referral[1] } 
          )) : null
        }
      }
      break
    default:
      break
      
  }
  console.log("mapped data", mappedData)
  return mappedData
}
