import React from "react";
import { FormGroup } from "../";
import { TextAreaInput } from "./TextAreaInput";
import { TextAreaHint } from "./TextAreaHint";
import { TextAreaLabel } from "./TextAreaLabel";
import { TextAreaCounter } from "./TextAreaCounter";

export const TextArea = ({
  name,
  label,
  helpText,
  value = "",
  rows,
  onChange,
  maxLength = 200,
  counter = false,
  disabled = false
}) => {
  const handleOnChange = (e) => onChange(e.target.value);

  return (
    <FormGroup>
      {label ? <TextAreaLabel htmlFor={name}>{label}</TextAreaLabel> : null}
      {helpText ? <TextAreaHint>{helpText}</TextAreaHint> : null}
      <TextAreaInput
        id={name}
        name={name}
        onChange={handleOnChange}
        value={value}
        rows={rows}
        maxLength={maxLength}
        disabled={disabled}
      />
      {counter && (
        <TextAreaCounter>You have {maxLength - value.toString().length} characters remaining</TextAreaCounter>
      )}
    </FormGroup>
  );
};
