import React, { useState, useEffect } from "react";
import {connect} from 'react-redux';
import { Form, Field } from "react-final-form";
import {
  BackLink,
  InputField,
  H1,
  Button,
  Radio,
  FlexContainer,
  InputHint,
  H4,
  CancelLink,
} from "../components/govuk-react";
import { withRouter, useLocation } from "react-router-dom";
import store from './../redux/store';
import {addPatientData, updatePatientData} from './../redux/slices/patient_profile';
import {required, composeValidators, dateError} from './Errors';
import { deleteFields } from "../utils/helpers.js";

const CreateRecord = ({ history, patient_record }) => {
  useEffect(() => {

    const handleEnterPress = (e) => {
      if(e.key === "Enter"){
        const submit = document.getElementById("submit")
        submit && submit.click()
      }
    }
    document.addEventListener("keydown", (e) => handleEnterPress(e))
    return document.removeEventListener("keydown", (e) => handleEnterPress(e))
  }, [])

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const update = useQuery().get("update");

  const onSubmit = (values) => {
    const {dob_day, dob_month, dob_year} = values
    values.dob = [dob_day, dob_month, dob_year]
    deleteFields(["dob_day", "dob_month", "dob_year"], values)
    update ? 
      store.dispatch(updatePatientData(values, "PP/updateCreateRecord", patient_record.patientid, values.contract)) :
      store.dispatch(addPatientData(values, values.contract)) 
    history.push("/patient_summary");
  };
  
  return (
    <>  
      <BackLink onClick={history.goBack}>Back to previous page</BackLink>
      <Form
        onSubmit={onSubmit}
        initialValues={{ 
          contract: patient_record.contract,
          post_code: patient_record.post_code,
          dob_day: patient_record.dob && patient_record.dob[0],
          dob_month: patient_record.dob && patient_record.dob[1],
          dob_year: patient_record.dob && patient_record.dob[2]
        }}
        render={({
          handleSubmit, initialValues }) => (
          <form onSubmit={(e) => handleSubmit(e)}>
              <H1>Patient reference information</H1>

              <H4>Contract type</H4>
              <FlexContainer>
                <Field name="contract" type="radio" value="WHP" >
                  {({ input, meta }) => {
                    return (
                      <div>
                        <Radio
                          name={input.name}
                          type="radio"
                          value={input.value}
                          label={"WHP"}
                          checked={input.checked}
                          onChange={input.onChange}
                        />
                        {meta.error && meta.touched && <span>{meta.error}</span>}
                      </div>
                    );
                  }}
                </Field>
                <Field name="contract" type="radio" value="IAPT" >
                  {({ input }) => {
                    return (
                      <>
                        <Radio
                          name={input.name}
                          type="radio"
                          value={input.value}
                          label={"IAPT"}
                          checked={input.checked}
                          onChange={input.onChange}
                        />
                      </>
                    );
                  }}
                </Field>
              </FlexContainer>

              <H4>What is their date of birth?</H4>
              <InputHint>For example, 27 11 2020</InputHint>
              <FlexContainer width={"30%"}>
                <Field
                  
                  name="dob_day"
                >
                  {({ input, meta }) => (
                    <div>
                      <InputField
                        label="Day"
                        width={"75%"}
                        name={input.name}
                        value={input.value}
                        onChange={input.onChange}
                      />
                      {meta.error && meta.touched && <span>{meta.error}</span>}
                    </div>
                  )}
                </Field>
                <Field
                  
                  name="dob_month"
                >
                  {({ input, meta }) => (
                    <div>
                      <InputField
                        label="Month"
                        width={"75%"}
                        name={input.name}
                        value={input.value}
                        onChange={input.onChange}
                      />
                      {meta.error && meta.touched && <span>{meta.error}</span>}
                    </div>
                  )}
                </Field>
                <Field
                  
                  name="dob_year"
                >
                  {({ input, meta }) => (
                    <div>
                      <InputField
                        label="Year"
                        width={"100%"}
                        name={input.name}
                        value={input.value}
                        onChange={input.onChange}
                      />
                      {meta.error && meta.touched && <span>{meta.error}</span>}
                    </div>
                  )}
                </Field>
              </FlexContainer>


                <Field
                  name="post_code"
                >
                  {({ input, meta }) => (
                    <div>
                      <InputField
                        width={"12.5%"}
                        label="First part of their postcode"
                        name={input.name}
                        value={input.value}
                        onChange={input.onChange}
                      />
                      {meta.error && meta.touched && <span>{meta.error}</span>}
                    </div>
                  )}
                </Field>
                
              <Button type="submit">{update ? "Update record" : "Create record"}</Button>
              <CancelLink onClick={history.goBack}>Cancel</CancelLink>
          </form>
        )}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  const { patient_profile } = state
  return { patient_record: patient_profile.create_record }
}

export default withRouter(connect(mapStateToProps)(CreateRecord));
