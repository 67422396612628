import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { BLACK } from 'govuk-colours';
import { SPACING_POINTS } from '@govuk-react/constants';
import { link, shape, spacing, typography } from '@govuk-react/lib';
import {Link} from 'react-router-dom';

const Anchor = styled(Link)(
  typography.font({ size: 16 }),
  link.common(),
  link.styleText,
  {
    display: 'inline-block',
    position: 'relative',
    // margins here are not responsive, hence why they're not specified using withWhiteSpace
    marginTop: SPACING_POINTS[3],
    marginBottom: SPACING_POINTS[3],
    borderBottom: `1px solid ${BLACK}`,
    textDecoration: 'none',
  },
  spacing.withWhiteSpace()
);

export const CancelLink = props => <Anchor {...props} />;

CancelLink.propTypes = {
  /** Text that will appear in the back link */
  children: PropTypes.string,
  /**
   * Custom function to run when the `onClick` event is fired
   */
  onClick: PropTypes.func,
};

CancelLink.defaultProps = {
  children: 'Back',
  onClick: undefined,
};