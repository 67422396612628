import api from "../core";

export const getAllPatientsFromSearch = async (args) => {

  const params = {
    patientNumber: args.patientNumber,
    patientDob: args.dob,
    postcode: args.postcode,
  }

  try {
    const { data } = await api.get("/search/patient/", params);
    console.log("Got data", data);
    return data;
  } catch (error) {
    return error;
  }
};

export const findPatientById = async (contract, patientId) => {
  try {
    const { data } = await api.get(`/${contract}/patient/${patientId}`);
    return data;
  } catch (error) {
    return error;
  }
}

export const addNewPatient = async (contract, patientData) => {
  try {
    const { data } = await api.post(`/${contract}/patient/`, patientData);
    return { response: data };
  } catch (error) {
    return error;
  }
};

export const deletePatient = async (contract, patientId) => {
  try {
    const { data } = await api.delete(`/${contract}/patient/${patientId}`);
    return { response: data };
  } catch (error) {
    return error;
  }
};

export const updatePatient = async (contract, patientId, patientData) => {
  try {
    const { data } = await api.put(
      `/${contract}/patient/${patientId}`,
      patientData
    );
    return { response: data };
  } catch (error) {
    return error;
  }
};

export const queryPatients = async (contract, queryParams) => {
  try {
    const { data } = await api.post(`/${contract}/query`, queryParams);
    return { response: data };
  } catch (error) {
    return error;
  }
};

export const queryPatientsCount = async (contract, queryParams) => {
  try {
    const { data } = await api.post(`/${contract}/query/count`, queryParams);
    return { response: data };
  } catch (error) {
    return error;
  }
};
