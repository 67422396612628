import React from "react";
import { ReactComponent as Logo } from "../../images/sohas-icon.svg";
import GridCol from "../govuk-react/grid-col/src";
import GridRow from "../govuk-react/grid-row/src";
import { Link } from "../govuk-react";

const Header = ({logout}) => {
  return (
    <header className="govuk-header">
      <div className="govuk-width-container">
        <GridRow className="sohas-header">
          <GridCol setWidth="one-sixth" className="header-logo" href="/">
            <a href="/">
              <Logo />
            </a>
          </GridCol>
          <GridCol setWidth="four-sixths" className="sohas-header-title">
            <a href="/">
              <div>Sheffield Occupational Health Advisory Service</div>
            </a>
          </GridCol>
          <GridCol setWidth="one-sixth" className="header-logout">
            <Link onClick={() => logout()}>Sign out</Link>
          </GridCol>
        </GridRow>
      </div>
    </header>
  );
};
export default Header;
