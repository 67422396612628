export const composeValidators = (...validators) => value =>
validators.reduce((error, validator) => error || validator(value), undefined)

export const required = value => (value ? undefined : 'Required')

export const dateError = (value, type) => {
switch(type){
  case("month"):
    return value > 12 ? "please enter a valid month" : undefined
}
}