import React, { useState, useEffect } from "react";
import { AccordionSection } from "./AccordionSection";
import { AccordionSectionHeader } from "./AccordionSectionHeader";
import { AccordionHeadingButton } from "./AccordionHeadingButton";
import { AccordionIcon } from "./AccordionIcon";
import { AccordionSectionContent } from "./AccordionSectionContent";
import { AccordionSectionHeading } from "./AccordionSectionHeading";

export const Accordion = (({ name, title, children, open }) => {
  const [isOpen, setOpenState] = useState(open ? open : false);

  function handleToggle(e) {
    setOpenState(isOpen ? false : true);
    e.preventDefault();
  }

  useEffect(() => {
    setOpenState(open ? open : false);
  }, [open]);

  return (
    <AccordionSection>
      <AccordionSectionHeader>
        <AccordionSectionHeading>
          <AccordionHeadingButton
            aria-expanded={isOpen}
            id={name}
            aria-controls={name}
            onClick={handleToggle}
            type="button"
          >
            {title}
          </AccordionHeadingButton>
        </AccordionSectionHeading>
        <AccordionIcon aria-hidden="true" open={isOpen} />
      </AccordionSectionHeader>
      <AccordionSectionContent open={isOpen}>{children}</AccordionSectionContent>
    </AccordionSection>
  );
});
