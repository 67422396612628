import React, { lazy } from "react";
import { Switch, BrowserRouter as Router } from "react-router-dom";
import PatientSummary from "../pages/PatientSummary";
import PatientSearch from "../pages/PatientSearch";
import CreateRecord from "../pages/CreateRecord";
import SearchResults from "../pages/SearchResults";

const FormRoutes = lazy(() => import("./FormRoutes"));
const QueryRoutes = lazy(() => import("./QueryRoutes"));

const MainRoutes = () => {
  return (
    <Router>
      <Switch>
        <PatientSearch exact path='/' />
        <CreateRecord path='/create_record' />
        <FormRoutes path='/form/*' />
        <QueryRoutes path='/query/*' />
        <PatientSummary path='/patient_summary' />
        <SearchResults path='/search_results' />
      </Switch>
    </Router>
  );
};

export default MainRoutes;
