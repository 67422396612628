import styled from "styled-components";
import { Link } from "react-router-dom";

import theme from "../theme";

export const NavigationLink = styled(Link)`
  font-weight: 700;
  color: ${theme.colors.blue};
  text-decoration: none;

  &:visited {
    color: ${theme.colors.blue};
  }

  &:link {
    color: ${theme.colors.blue};
  }

  &:hover {
    color: ${theme.colors.blue};
    text-decoration: underline;
  }

  &:focus {
    outline: 3px solid transparent;
    color: ${theme.colors.black};
    background-color: ${theme.colors.yellow};
    box-shadow: 0 -2px ${theme.colors.yellow}, 0 4px ${theme.colors.black};
    text-decoration: none;
  }
`;

NavigationLink.displayName = "NavigationLink";
