import React from "react";
import Header from "./Header";
import Footer from "./Footer";
//import keycloak from "../../utils/keycloak";
import { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import Spinner from "./Spinner";
import { Suspense } from "react";

const Secured = ({ children, history }) => {
  //const [auth, setAuth] = useState({ keycloak: null, authenticated: false });

  /*
  useEffect(() => {
    const keycloak = Keycloak;
    keycloak.init({ onLoad: "login-required" }).then((authenticated) => {
    setAuth({ keycloak: keycloak, authenticated: authenticated });
  });
  }, [history]);
  */

  const { keycloak, initialized } = useKeycloak();

  const logout = () => {
    history.push("/");
    keycloak.logout();
  };

  return (
    <>
      <div className='govuk-template__body govuk-pipeline_body'>
        <Header logout={logout} />
        <main
          className='govuk-main-wrapper govuk-width-container App'
          id='main-content'
          role='main'
        >
          <>
            {keycloak.authenticated ? (
              <Suspense fallback={<Spinner />}>
                <>{children}</>
              </Suspense>
            ) : null}
          </>
        </main>
      </div>
      <Footer />
    </>
  );
};

export default withRouter(Secured);
