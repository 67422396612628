import api from "./../../api/core";
import * as R from 'ramda';

export const initialState = {
  patient_info: {
    ethnicity: [],
    healthproblem: [],
    referredby: [],
    practice: [],
    sex: [],
  },
  employment_details: {
    currentstatus: [],
    generaljob: [],
    exposure: []
  },
  advice_sessions: {

  }
}

export const dropdownItemsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ("DD/patient_info"): {
      return {
          ...state,
          patient_info: action.payload
        }
    }
    case ("DD/employment_details"): {
      return {
          ...state,
          employment_details: action.payload
        }
    }
    case ("DD/advice_sessions"): {
      return {
          ...state,
          advice_sessions: action.payload
        }
    }
    case ("DD/getAllDropDowns"): {
      return {
        state
      }
    }
    default:
      return state
  }
}

const getDropDown = (type) => {
  return api.get(`selections/findall/${type}`)
}

const dropDownsByPage = {
  patient_info: ["ethnicity", "healthproblem", "referredby", "practice", "sex"],
  employment_details: ["currentstatus", "job", "generaljob", "exposure"],
  advice_sessions: ["test", "advice", "advicemethod", "benefit", "referredto", "adviser", "contacttype"]
}

export const getDropdownItems = () => {
  return (dispatch) => {

    let dropdownMappings = {}
    Object.keys(dropDownsByPage).forEach(async page => {
      await Promise.all(dropDownsByPage[page].map(dropDown => {
        return getDropDown(dropDown)
          .then(res => {
            dropdownMappings[dropDown] = R.mergeAll(res.data.map((item => {return {[Object.values(item)[0]]: Object.values(item)[1]}})))
            return {
              [dropDown]:res.data.map(item => Object.values(item)[0]).flat().filter(val => val!=="INIT"),
              [dropDown + "Mappings"]: R.mergeAll(res.data.map((item => {return {[Object.values(item)[0]]: Object.values(item)[1]}})))
            }
          })
      }))
      .then((res) => dispatch({type: `DD/${page}`, payload: Object.assign({}, ...res)}))
      sessionStorage.setItem("dropdownMappings", JSON.stringify(dropdownMappings))
    })
    
  };
}