import {addNewPatient, updatePatient, findPatientById} from './../../api/iapt/patient';
import {mapFEtoDB, mapConsultationsToFE} from './../helpers';
import {addNewConsultation, updateConsultation, getConsultations} from './../../api/iapt/consultation';

export const initialState = {
  create_record: {
    contract: "",
    patientid: 0,
    dob: [],
    post_code: "",
  },
  patient_information: {
    sex: [],
    referredby: "",
    ethnicity: [],
    practice: [],
    healthproblem: [],
  },
  employment_details: {
    currentstatus: [],
    job: [],
    workPattern: "",
    generaljob: [],
    exposure: [],
  },
  employer_details: {
    employerName: "",
    employerAddressLine1: "",
    employerAddressLine2: "",
    employerAddressLine3: "",
    employerPostCode: "",
    nameOfContact: "",
    numberOfContact: "",
    emailOfContact: "",
  },
  patient_summary_notes: [],
  advice_sessions: []
};

export const patientProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case "PP/initialiseStore": {
      return {
        ...initialState
      };
    }
    case "PP/createRecord": {
      return {
        ...initialState,
        create_record: action.payload,
      };
    }
    case "PP/updateCreateRecord": {
      return {
        ...state,
        create_record: {
          ...state.create_record,
          contract: action.payload.contract,
          dob: action.payload.dob,
          post_code: action.payload.post_code,
        },
      };
    }
    case "PP/updatePatientInfo": {
      return {
        ...state,
        patient_information: action.payload,
      };
    }
    case "PP/updateEmploymentDetails": {
      return {
        ...state,
        employment_details: action.payload,
      };
    }
    case "PP/updateEmployerDetails": {
      return {
        ...state,
        employer_details: action.payload,
      };
    }
    case "PP/updatePatientSummaryNotes": {
      return {
        ...state,
        patient_summary_notes: action.payload,
      };
    }
    case ("AS/addAdviceOutcome"): {
      return {
          ...state,
          advice_sessions: [...state.advice_sessions, action.payload]
        }
    }
    case ("AS/updateAdviceOutcome"): {
      return {
          ...state,
          advice_sessions: action.payload
        }
    }
    case "PP/getAllPatientInfo": {
      return {
        ...state,
        create_record: {
          contract: action.payload.contract,
          dob: action.payload.patientData.patientdob ? action.payload.patientData.patientdob.split("/") : null,
          post_code: action.payload.patientData.patientpostcode ? action.payload.patientData.patientpostcode : null,
          patientid: action.payload.patientData.patientid,
          patientnumber: action.payload.patientData.patientnumber
        },
        patient_information: {
          sex: action.payload.sex ? [action.payload.sex.sex, action.payload.sex.sexid]  : null,
          referredby: action.payload.patientData.referredby ? [action.payload.patientData.referredby.referredby, action.payload.patientData.referredby.referredbyid ] : null,
          ethnicity: action.payload.patientData.ethnicity ? [action.payload.patientData.ethnicity.ethnicity, action.payload.patientData.ethnicity.ethnicityid ] : null,
          practice: action.payload.patientData.practice ? [action.payload.patientData.practice.practice, action.payload.patientData.practice.practiceid ] : null,
          patientjobid: action.payload.patientJobId ? action.payload.patientJobId : null,
          healthproblem: action.payload.healthProblems,
        },
        employment_details: {
          currentstatus: action.payload.patientData.currentstatus ? [action.payload.patientData.currentstatus.currentstatus, action.payload.patientData.currentstatus.currentstatusid ] : null,
          job: action.payload.latestJob ? [action.payload.latestJob.job, action.payload.latestJob.jobid] : null,
          workPattern: action.payload.patientData.patient0neither1ft2pt ? action.payload.patientData.patient0neither1ft2pt : null,
          generaljob: action.payload.generalJob ? [action.payload.generalJob.generaljob, action.payload.generalJob.generaljobid] : null,
          patientjobid: action.payload.patientJobId ? action.payload.patientJobId : null,
          exposure: action.payload.exposures,
        },
        employer_details: {
          employerName: action.payload.jobsArray[0] ? action.payload.jobsArray[0].patientemployer : null,
          employerAddressLine1: action.payload.jobsArray[0] ? action.payload.jobsArray[0].patientjobcontact1address : null,
          employerAddressLine2: action.payload.jobsArray[0] ? action.payload.jobsArray[0].patientjobcontact2address : null,
          employerAddressLine3: action.payload.jobsArray[0] ? action.payload.jobsArray[0].patientjobcontact3address : null,
          employerPostCode: action.payload.jobsArray[0] ? action.payload.jobsArray[0].patientjobcontactpostcode : null,
          nameOfContact: action.payload.jobsArray[0] ? action.payload.jobsArray[0].patientjobcontact : null,
          numberOfContact: action.payload.jobsArray[0] ? action.payload.jobsArray[0].patientjobcontactphone : null,
          emailOfContact:  action.payload.jobsArray[0] ? action.payload.jobsArray[0].patientjobcontactemail : null,
          patientjobid: action.payload.patientJobId ? action.payload.patientJobId : null,
        },
        patient_summary_notes: ""
      }
    }
    case "PP/getAdviceSessions": {
      return {
        ...state,
        advice_sessions: action.payload.map(mapConsultationsToFE)
      }
    }
    default:
      return state;
  }
}

const initialValues = {
  patientconsultationpracticesessionyn: 0,
  patientmhyn: 0,
  patientcurrentwrihyn: 0,
  patientpastwrihyn: 0,
}

export const addPatientData = (data, contract) => {
  return (dispatch) => {
    addNewPatient(contract, Object.assign(mapFEtoDB(data, "PP/createRecord"), initialValues))
      .then(res => dispatch(
        {
          type: "PP/createRecord", 
          payload: Object.assign(
            data, 
            {
              contract, 
              patientid: res.response.patientid, 
              patientnumber: res.response.patientnumber,
              patientjobid: res.response.patientjobid,
            }
          )
        })
      )
  };  
}

export const updatePatientData = (data, type, patientid, contract) => {
  return (dispatch) => {
    updatePatient(contract, patientid, mapFEtoDB(data, type))
      .then(() => dispatch({type: type, payload: data}))
    return "Success"
  };  
}

export const addAdviceOutcome = (data, contract) => {
  return (dispatch) => {
    addNewConsultation(contract, mapFEtoDB(data, "AS/addAdviceOutcome"))
      .then(res => {
        dispatch(
        {
          type: "AS/addAdviceOutcome", 
          payload: Object.assign(data, {consultationId: res.response.consultationId})
        })
      })
      return "Success"
  };  
}

export const updateAdviceOutcome = (data, advice_sessions_clone, contract, consultationId) => {
  return (dispatch) => {
    updateConsultation(contract, mapFEtoDB(data, "AS/addAdviceOutcome"), consultationId)
      .then(() => dispatch(
        {
          type: "AS/updateAdviceOutcome", 
          payload: advice_sessions_clone
        })
      )
      return "Success"
  };  
}

export const getPatientInfoForSummary = (contract, id) => {
  return (dispatch) => {
    findPatientById(contract, id)
      .then(responseA => dispatch({ type: "PP/getAllPatientInfo", payload: responseA }))
      .then(() => getConsultations(contract, id))
      .then(responseB => dispatch({type: "PP/getAdviceSessions",  payload: responseB }))
  }
}
