import { BrowserRouter, Route } from "react-router-dom";
import GlobalStyle from "./GlobalStyle";
import Secured from "./components/core/Secured";
import MainRoutes from "./routes/MainRoutes";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from "./utils/keycloak";
import { Provider } from "react-redux";
import store, { persistor } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";

const keycloakProviderInitConfig = {
  onLoad: "login-required",
  checkLoginIframe: false,
};

function App() {
  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={keycloakProviderInitConfig}
    >
      <BrowserRouter>
        <GlobalStyle />
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <Secured>
                <MainRoutes />
            </Secured>
          </PersistGate>
        </Provider>
      </BrowserRouter>
    </ReactKeycloakProvider>
  );
}

export default App;
