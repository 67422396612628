import styled, { css } from "styled-components";
import theme from "../theme";

const leftStyle = css`
  height: auto;
  margin: 0 auto 20px;
  flex: 1;
`;

const bottomBorderStyle = css`
  border-bottom: 4px solid ${theme.colors.midGrey};
`;

export const NavigationList = styled.ul`
  box-sizing: border-box;
  height: ${({ height = "53px" }) => height};
  line-height: 53px;
  padding: 0;
  list-style: none;
  margin: 0 auto;
  display: flex;

  flex-direction: ${({ direction = "horizontal" }) =>
    direction === "horizontal" ? "row" : "column"};

  ${({ enableLeftStyle }) => (enableLeftStyle === true ? leftStyle : "")};
  ${({ enableBottomBorderStyle }) =>
    enableBottomBorderStyle === true ? bottomBorderStyle : ""};
`;

NavigationList.displayName = "NavigationList";
