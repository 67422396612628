import styled from 'styled-components';
import theme from '../theme';

export const H1 = styled.h1`
  font-size: ${theme.typography.h1.fontSize};
  margin-top: 30px;
  margin-bottom: 40px;
  ${({margin}) => margin && { margin }};
`
export const H2 = styled.h2`
  font-size: ${theme.typography.h2.fontSize};
  ${({margin}) => margin && { margin }};
`
export const H3 = styled.h3`
  font-size: ${theme.typography.h3.fontSize};
  margin-bottom: 10px;
  ${({margin}) => margin && { margin }};
`
export const H4 = styled.h4`
  font-size: ${theme.typography.h4.fontSize};
  ${({margin}) => margin && { margin }};
`