import React, {useEffect} from "react";
import { withRouter } from "react-router-dom";
import {
  H1,
  H3,
  Button,
  Link,
  BackLink,
} from "../components/govuk-react";
import {connect} from 'react-redux';
import Table from "../components/govuk-react/table/src/index.js";
import { convertTimestampToDate } from "../utils/helpers";
import store from "../redux/store";
import { getPatientInfoForSummary } from "../redux/slices/patient_profile";


const SearchResults = ({ history, searchResults }) => {
  const totalResults = searchResults.length;
  const sortedResults = searchResults ? searchResults.sort((a, b) => 
    a.patientnumber - b.patientnumber
  ) : [];

  let searchParams = new URLSearchParams(document.location.search);
  let searchValues = [];
  let dob = searchParams.get("patientDob");
  let postcode = searchParams.get("postcode");
  let patient = searchParams.get("patientNumber");

  if(dob && dob !== "undefined") {
    searchValues.push(convertTimestampToDate(dob));
  }

  if(postcode && postcode !== "undefined") {
    searchValues.push(postcode.toUpperCase());
  }

  if(patient && patient !== "undefined") {
    searchValues.push(patient);
  }

  const searchValuesMap = searchValues.map(value => (
    `"${value}" `
  ))

  return (
    <>
      <BackLink onClick={history.goBack}>Back</BackLink>
      {
        searchResults.length === 0 ? 
        (
          <>
          <H1>No results found for {searchValuesMap}
                </H1> 
          <Link to="/">Return to search page</Link>
          </>
        ) : 
        (
          <>
          <H1>Search results</H1>
          <H3>{totalResults} results for {searchValuesMap}</H3>
          <Table
            head={
              <Table.Row>
                <Table.CellHeader setWidth='one-third'>Patient number</Table.CellHeader>
                <Table.CellHeader setWidth='one-third'>Date of birth</Table.CellHeader>
                <Table.CellHeader setWidth='one-third'>First part of postcode</Table.CellHeader>
              </Table.Row>
            }
          >
            {
              sortedResults.map(result => {
                return(
                  <Table.Row key={result.patientid}>
                  <Table.Cell>
                    <Button 
                      onClick={
                        () => store.dispatch(getPatientInfoForSummary(result.contract, result.patientid),
                        history.push('/patient_summary')
                      )} 
                      style={{
                        color: '#1D70B8', 
                        fontSize: '18px', 
                        backgroundColor: 'white', 
                        textDecoration: 'underline', 
                        boxShadow: 'none', 
                        marginTop: '0px', 
                        marginBottom: '0px'
                      }}>
                      {result.patientnumber}
                      </Button>
                    </Table.Cell> 
                  <Table.Cell style={{fontSize: '18px'}}>{convertTimestampToDate(result.patientdob)}</Table.Cell>
                  <Table.Cell style={{fontSize: '18px'}}>{result.patientpostcode.toUpperCase()}</Table.Cell>
                </Table.Row>
                )
              })
            }
          </Table>
          </>
        )
      }
    </>
  )
};

const mapStateToProps = (state) => {
  const { search_results } = state
  return {
    searchResults: search_results.search_results
  }
}


export default withRouter(connect(mapStateToProps)(SearchResults));
