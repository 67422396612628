import React from "react";
import styled from "styled-components";

const PaginationIterators = styled.span``;
PaginationIterators.displayName = "PaginationIterators";

export const PaginationNext = () => (
  <>
    Next{" "}
    <PaginationIterators aria-hidden="true" role="presentation">
      &raquo;
    </PaginationIterators>
  </>
);

export const PaginationPrev = () => (
  <>
    <PaginationIterators aria-hidden="true" role="presentation">
      &laquo;
    </PaginationIterators>{" "}
    Previous
  </>
);
