import { withRouter } from "react-router-dom";
import {
  SummaryList,
  SummaryListRow,
  SummaryListKey,
  SummaryListValue,
  Accordion,
  P,
  H2,
  H3,
  Link,
} from "../components/govuk-react";
import AdviceOutcome from './AdviceOutcome';

const AdviceSummary = ({adviceSession, index}) => {

  const getReadableDate = (dateString) => {
    const dateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    const date = new Date(dateString)
    return date.toLocaleDateString(undefined, dateOptions)
  }

  const adviceOutcomes = adviceSession.outcomes && adviceSession.outcomes.map((outcome, index) => {
    return (
      <>
        <H3>Advice outcome {index + 1} </H3>
        <AdviceOutcome key={index} adviceOutcome={outcome} />
      </>
    )
  })

  const referrals = adviceSession.referredto && !adviceSession.referredto.includes(null || undefined) ? 
    adviceSession.referredto.map((referral, index) => {
      return <P style={{ marginTop: "0px", marginBottom: "0px"}} key={index}>{referral[0]}</P> 
    }) : null;

  return (
    <>
      <Accordion title={getReadableDate(adviceSession.patientconsultationdate)}>
        <H2>Appointment details</H2>
        <SummaryList>
          <SummaryListRow>
            <SummaryListKey>Date of appointment</SummaryListKey>
            <SummaryListValue>{getReadableDate(adviceSession.patientconsultationdate)}</SummaryListValue>
          </SummaryListRow>
          <SummaryListRow>
            <SummaryListKey>Adviser name</SummaryListKey>
            <SummaryListValue>{adviceSession.adviser && adviceSession.adviser[0]}</SummaryListValue>
          </SummaryListRow>
          <SummaryListRow>
            <SummaryListKey>Contact type</SummaryListKey>
            <SummaryListValue>{adviceSession.contacttype && adviceSession.contacttype[0]}</SummaryListValue>
          </SummaryListRow>
          <SummaryListRow>
            <SummaryListKey>Referrals</SummaryListKey>
            <SummaryListValue>{referrals}</SummaryListValue>
        </SummaryListRow>
        </SummaryList>
        <H2>Advice session</H2>
        <SummaryList>
         {adviceOutcomes}
        </SummaryList>
        <H3>Session notes</H3>
        <P>
          {adviceSession.patientconsultationnotes}
        </P>
        <Link to={`/form/session_outcomes/true/${index}`}>
          Update advice session
        </Link>
      </Accordion>

    </>
  );
};

export default withRouter(AdviceSummary);