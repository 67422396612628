import Keycloak from "keycloak-js";

const keycloak = new Keycloak({
  realm: process.env.REACT_APP_KEYCLOAK_REALM,
  url: process.env.NODE_ENV === "production" ?
  process.env.REACT_APP_KEYCLOAK_URL_PROD :
  process.env.REACT_APP_KEYCLOAK_URL_DEV,
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
});

//const keycloak = new Keycloak("/keycloak.dev.json");
export default keycloak;
