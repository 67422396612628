import axios from "axios";
import keycloak from "../utils/keycloak";

const headers = { "Content-Type": "application/json" };

const instance = axios.create({
  baseURL:
    process.env.NODE_ENV === "production"
      ? "https://db.sohas.co.uk/api"
      : "http://localhost:6060/api",
  headers,
});

export default {
  get: (endpoint, params) =>
    instance({
      headers: { Authorization: `Bearer ${keycloak.token}` },
      method: "GET",
      url: endpoint,
      params: params,
    }),
  post: (endpoint, data) =>
    instance({
      headers: { Authorization: `Bearer ${keycloak.token}` },
      method: "POST",
      url: endpoint,
      data: data,
    }),
  postDownload: (endpoint, data) =>
    instance({
      headers: { Authorization: `Bearer ${keycloak.token}` },
      method: "POST",
      url: endpoint,
      data: data,
      responseType: "blob",
    }),
  put: (endpoint, data) =>
    instance({
      headers: { Authorization: `Bearer ${keycloak.token}` },
      method: "PUT",
      url: endpoint,
      data: data,
    }),
  delete: (endpoint) =>
    instance({
      headers: { Authorization: `Bearer ${keycloak.token}` },
      method: "DELETE",
      url: endpoint,
    }),
};
