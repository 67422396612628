import React from "react";
import styled from "styled-components";
import { TextAlignProps } from "styled-system";

const StyledPaginationSummary = styled.div.attrs(() => ({ tabIndex: 0 }))`
  padding: 8px 0;

  @media (min-width: 40.0625em) {
    /* float: right; */
    text-align: ${({ textAlign }) => (textAlign === "left" ? "left" : "right")};
  }
`;

export const PaginationSummary = ({
  page,
  results,
  pageSize = 25,
  resultText,
  textAlign
}) => {
  const pages = Math.ceil(results / pageSize);
  const endCursor = page === pages ? 0 : pageSize * page;
  const startCursor = page === pages ? "last page" : endCursor - pageSize + 1;
  const description = resultText === "" || typeof resultText === "undefined" ? "results" : resultText;

  return (
    <StyledPaginationSummary textAlign={textAlign}>
      {`Showing ${startCursor} ${endCursor ? "-" : ""} ${
        endCursor ? endCursor : ""
      } of ${results} ${description}`}
    </StyledPaginationSummary>
  );
};
