import React, { useEffect } from "react";
import { Field, Form } from "react-final-form";
import Search from "../components/govuk-react/icons/src/Search/index.js";
import {
  H1,
  H3,
  P,
  Button,
  H4,
  InputHint,
  FlexContainer,
  InputField,
} from "../components/govuk-react";
import GridRow from "../components/govuk-react/grid-row/src/index.js";
import GridCol from "../components/govuk-react/grid-col/src/index.js";
import { connect } from "react-redux";
import { required, composeValidators, dateError } from "./Errors";
import store from "./../redux/store";
import { getDropdownItems } from "./../redux/slices/dropdown_items";
import { getPatientData } from "./../redux/slices/search_results.js";
import { withRouter } from "react-router";
import { convertDOBToTimestamp, deleteFields } from "../utils/helpers.js";

const PatientSearch = ({ initialiseStore, history }) => {
  const onSubmit = async (values) => {
    if (values.dob_day && values.dob_month && values.dob_year) {
      values.dob = convertDOBToTimestamp(
        values.dob_day,
        values.dob_month,
        values.dob_year
      );
      deleteFields(["dob_day", "dob_month", "dob_year"], values);
    }
    if (Object.keys(values).length > 0) {
      store.dispatch(getPatientData(values));
      history.push({
        pathname: "/search_results",
        search: `?patientNumber=${values.patientNumber}&patientDob=${values.dob}&postcode=${values.postcode}`,
      });
    } else {
      // Refresh page if values empty
      history.push("/");
    }
  };

  useEffect(() => {
    initialiseStore();
    store.dispatch(getDropdownItems());
  }, []);

  return (
    <div className='govuk-width-container'>
      <GridRow>
        <GridCol setWidth='two-thirds'>
          <H1 margin={"20px 0px 10px"}>SOHAS records</H1>
          <P margin={"10px 0px 60px"}>
            Create and manage patient records and advice sessions.
          </P>
        </GridCol>
      </GridRow>
      <GridRow>
        <GridCol setWidth='one-half'>
          <hr color='#F0932E' />
          <H1 margin={"20px 0px 30px"}>Search for a patient</H1>
          <Form
            onSubmit={onSubmit}
            render={({ handleSubmit, values }) => (
              <form onSubmit={handleSubmit}>
                <Field name='patientNumber'>
                  {(props) => (
                    <div>
                      <InputField
                        width={"75%"}
                        label='Patient number'
                        name={props.input.name}
                        value={props.input.value}
                        onChange={props.input.onChange}
                      />
                    </div>
                  )}
                </Field>

                <H4>What is their date of birth?</H4>
                <InputHint>For example, 27 11 2020</InputHint>
                <FlexContainer width={"60%"}>
                  <Field
                    validate={(value) => dateError(value, "day")}
                    name='dob_day'
                  >
                    {({ input, meta }) => (
                      <div>
                        <InputField
                          label='Day'
                          width={"75%"}
                          name={input.name}
                          value={input.value}
                          onChange={input.onChange}
                        />
                        {meta.error && meta.touched && (
                          <span>{meta.error}</span>
                        )}
                      </div>
                    )}
                  </Field>
                  <Field
                    validate={composeValidators((value) =>
                      dateError(value, "month")
                    )}
                    name='dob_month'
                  >
                    {({ input, meta }) => (
                      <div>
                        <InputField
                          label='Month'
                          width={"75%"}
                          name={input.name}
                          value={input.value}
                          onChange={input.onChange}
                        />
                        {meta.error && meta.touched && (
                          <span>{meta.error}</span>
                        )}
                      </div>
                    )}
                  </Field>
                  <Field
                    validate={(value) => dateError(value, "year")}
                    name='dob_year'
                  >
                    {({ input, meta }) => (
                      <div>
                        <InputField
                          label='Year'
                          width={"100%"}
                          name={input.name}
                          value={input.value}
                          onChange={input.onChange}
                        />
                        {meta.error && meta.touched && (
                          <span>{meta.error}</span>
                        )}
                      </div>
                    )}
                  </Field>
                </FlexContainer>

                <Field name='postcode'>
                  {({ input, meta }) => (
                    <div>
                      <InputField
                        width={"25%"}
                        label='First part of their postcode'
                        name={input.name}
                        value={input.value}
                        onChange={input.onChange}
                      />
                      {meta.error && meta.touched && <span>{meta.error}</span>}
                    </div>
                  )}
                </Field>

                <Button type='submit' isStart={true} icon={<Search />}>
                  Search
                </Button>
              </form>
            )}
          />
        </GridCol>
        <GridCol className='home-right' setWidth='one-half'>
          <hr color='#F0932E' />
          <a href='/create_record'>
            <H1 margin={"20px 0px 30px"}>Create a new record</H1>
          </a>
          <P>Add a new record to the system.</P>
          <br />
          <br />
          <br />
          <hr color='#F0932E' />
          <a href='/query/programmes'>
            <H1 margin={"20px 0px 30px"}>Query patient records</H1>
          </a>
          <P>Query and export information on existing patients.</P>
        </GridCol>
      </GridRow>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    initialiseStore: () => {
      dispatch({ type: "PP/initialiseStore", payload: "" });
    },
  };
};

export default withRouter(connect(null, mapDispatchToProps)(PatientSearch));
