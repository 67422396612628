import styled from "styled-components";

import { InputHint } from "./../InputField/InputHint";

export const TextAreaCounter = styled(InputHint)`
  margin-top: 0;
  margin-bottom: 0;
`;

TextAreaCounter.displayName = "TextAreaCounter";
