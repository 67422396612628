import styled from "styled-components";
import theme from "../theme";

export const RadioInput = styled.input`
  cursor: pointer;
  position: absolute;
  z-index: 1;
  top: -2px;
  left: -2px;
  width: 36px;
  height: 44px;
  margin: 0;
  opacity: 0;

  :focus + label::before {
    border-width: 4px;
    box-shadow: 0 0 0 4px ${theme.colors.yellow};
  }
  :checked + label::after {
    opacity: 1;
  }
`;
