import styled from "styled-components";
import theme from "../theme";

export const TextAreaInput = styled.textarea`
  font-weight: 400;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.25;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
  width: 100%;
  min-height: 40px;
  margin-bottom: 20px;
  padding: 5px;
  resize: vertical;
  border: 2px solid ${theme.colors.black};
  border-radius: 0;
  -webkit-appearance: none;

  &:focus {
    outline: 3px solid ${theme.colors.yellow};
    outline-offset: 0;
    -webkit-box-shadow: inset 0 0 0 2px;
    box-shadow: inset 0 0 0 2px;
  }

  @media (min-width: 40.0625em) {
    font-size: 19px;
    font-size: 1.1875rem;
    line-height: 1.25;
    margin-bottom: 30px;
  }
`;

TextAreaInput.displayName = "TextAreaInput";
