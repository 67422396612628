import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`

  html * {
    font-family: "Helvetica", Sans-Serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }


  // MultiSelect component overrides
  .rmsc {
    --rmsc-border: #fff !important;
    --rmsc-radius: 0px !important;
    --rmsc-h: 33px !important;
    --rmsc-p: 10px !important;
    font-size: 18px !important;
    width: 50%;
    border: 2px solid #0b0c0c !important;
    boxSizing: 'border-box';
  }
`;

export default GlobalStyle;
