import { Link } from "react-router-dom";
import theme from '../theme';
import styled, {css} from 'styled-components';
import { darken } from 'polished';

const isStartStyles = css`
  font-size: 24px;
  line-height: 19px;
  font-weight: bold;
`

export const ButtonLink = styled(Link)`
  background-color: ${theme.colors.green};
  position: relative;
  display: inline-block;
  padding: 6px 12px;
  border: none;
  border-radius: 0;
  outline: 1px solid transparent;
  outline-offset: -1px;
  box-shadow: 0 2px 0 ${darken(0.05, theme.colors.green)};
  font-size: 19px;
  ${({isStart}) => isStart && {isStartStyles}}};
  text-decoration: none;
  cursor: pointer;
  color: ${theme.colors.white};

  box-sizing: border-box;
  vertical-align: top;
  display: inline-block;

  &:visited {
    background-color: ${theme.colors.green};
  }

  &:hover,
  &:focus {
    background-color: ${darken(0.05, theme.colors.green)};
  }

  &:active {
    top: 2px;
    box-shadow: 0 0 0 ${darken(0.05, theme.colors.green)};
  }

  &:link,
  &:hover,
  &:focus,
  &:visited {
    color: ${theme.colors.white};
  }

  &:before {
    content: "";
    height: 110%;
    width: 100%;
    display: block;
    background: transparent;
    position: absolute;
    top: 0;
    left: 0;
  }

  &:active:before {
    top: -10%;
    height: 120%;
  }

  @media (max-width: 640px) {
    width: 100%;
    text-align: center;
  }

  &:focus {
    outline: 3px solid ${theme.colors.yellow};
  }
`;
