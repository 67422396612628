import styled, { css } from "styled-components";

const errorStyle = css`
  padding-left: 15px;
  border-left: 5px solid #d4351c;
`;

export const FormGroupValidation = styled.div`
  margin-bottom: 25px;
  ${({ isValid = true }) => (isValid ? null : errorStyle)};
`;

FormGroupValidation.displayName = "FormGroupValidation";
