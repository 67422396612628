import React from "react";
import styled, { css } from "styled-components";
import theme from "../theme";

const closedStyle = css`
  &::after {
    height: 100%;
  }
`;

const openStyle = css``;

export const AccordionIcon = React.memo(styled.span`
  position: absolute;
  top: 50%;
  right: 15px;
  width: 16px;
  height: 16px;
  margin-top: -8px;

  &::after,
  &::before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 25%;
    height: 25%;
    margin: auto;
    border: 2px solid transparent;
    background-color: ${theme.colors.black};
  }

  &::before {
    width: 100%;
  }

  ${({ open = false }) => (open ? openStyle : closedStyle)};
`);

AccordionIcon.displayName = "AccordionIcon";
