import styled from "styled-components";
import theme from "../theme";

export const AccordionSectionHeader = styled.div`
  position: relative;
  padding-right: 40px;
  border-top: 1px solid ${theme.colors.midGrey};
  color: ${theme.colors.blue};
  cursor: pointer;
  padding-top: 15px;
  padding-bottom: 15px;
`;

AccordionSectionHeader.displayName = "AccordionSectionHeader";
