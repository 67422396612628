import styled from "styled-components";
import theme from "../theme";

export const SummaryList = styled.dl`
  color: ${theme.colors.black};
  margin: 0;
  margin-bottom: 20px;

  @media (min-width: 40.0625em) {
    margin-bottom: 30px;
    display: table;
    width: 85%;
    table-layout: fixed;
    font-size: 19px;
    /* font-size: 16px; */
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
`;
