import React from "react";
import { InputLabel, InputHint, Input } from ".";
import styled, { css } from "styled-components";

const errorStyle = css`
  padding-left: 15px;
  border-left: 5px solid #d4351c;
`;

const FormGroup = styled.form`
  margin-bottom: 30px;
  display: flex;
  ${({ isValid = true }) => (isValid ? null : errorStyle)}
`;

export const InputFieldIcon = ({
  label,
  name,
  helpText,
  onChange,
  value,
  placeholder,
  size,
  valid,
  children,
  disabled = false
}) => {
  const handleOnChange = (e) => {
    onChange(e.target.value);
  };

  return (
    <FormGroup>
      {label ? <InputLabel htmlFor={name}>{label}</InputLabel> : null}
      {helpText ? <InputHint id={`${name}-hint`}>{helpText}</InputHint> : null}
      <Input
        id={name}
        name={name}
        onChange={handleOnChange}
        value={value}
        size={size}
        aria-describedby={`${name}-hint`}
        data-test={`input-${name}`}
        isValid={valid}
        disabled={disabled}
        placeholder={placeholder}
      />
      {children}
    </FormGroup>
  );
};
