import styled from "styled-components";
import theme from "../theme";

export const Column = styled.div`
  ${({ border }) => border && { border }}
  ${({ borderColor }) =>
    typeof borderColor === "string" && { borderColor: theme.colors[borderColor] }}
`;

Column.displayName = "Column";
