import styled from "styled-components";
import { flexbox } from "styled-system";

export const FlexContainer = styled.div`
  display: flex;
  ${flexbox}
  ${({ customHeight }) => (customHeight ? "height: " + customHeight : "")};
  ${({ customMarginTop }) => (customMarginTop ? "margin-top: " + customMarginTop : "")};
  ${({ customMarginBottom }) =>
    customMarginBottom ? "margin-bottom: " + customMarginBottom : ""};
  ${({ width }) => width && {width}};
`;

FlexContainer.displayName = "FlexContainer";
