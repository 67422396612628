import { patientProfileReducer } from "./slices/patient_profile";
import { dropdownItemsReducer } from "./slices/dropdown_items";
import { searchResultsReducer } from "./slices/search_results";
import { queryReducer } from "./slices/query";
import { combineReducers } from "redux";

const rootReducer = combineReducers({
  patient_profile: patientProfileReducer,
  dropdown_items: dropdownItemsReducer,
  search_results: searchResultsReducer,
  query: queryReducer,
});

export default rootReducer;
